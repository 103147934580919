import React, { useEffect } from "react";
import {
  PulseAnimation,
  Heading,
  Modal,
  MessageModalLayout,
  Text,
  Card,
  FormField,
  Box,
  Page,
  InputArea,
  Button,
  Cell,
  Layout,
  Input,
  WixDesignSystemProvider,
  Badge,
  MarketingLayout,
  AnnouncementModalLayout,
  Notification,
  Image,
  Loader,
  IconButton,
} from "@wix/design-system";
import "@wix/design-system/styles.global.css";
import * as Icons from "@wix/wix-ui-icons-common";
import "./App.css";
import { ReactTagManager } from "react-gtm-ts";
import Expert from "./expert.png";
import Jasper from "./jasper.png";

const tagManagerArgs = {
  code: "GTM-WZQPMC7",
};

function App() {
  ReactTagManager.init(tagManagerArgs);
  const [isFirstTime, setIsFirstTime] = React.useState(true);
  const [prompt, setPrompt] = React.useState("");
  const [isProcessing, setIsProcessing] = React.useState(false);
  const [completion, setCompletion] = React.useState("");
  const [isUpgraded, setIsUpgraded] = React.useState(false);
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [isUpgradeModalOpen, setIsUpgradeModalOpen] = React.useState(false);
  const [isFairUsageModalOpen, setIsFairUsageModalOpen] = React.useState(false);
  const [editInstructions, setEditInstructions] = React.useState("");
  // create ref
  const inputAreaRef = React.useRef(null);
  const [isBeta, setIsBeta] = React.useState(true);
  const [instanceData, setInstanceData] = React.useState({
    instance: {
      isFree: true,
      availablePlans: [],
      instanceId: "",
    },
  });

  const token = new URLSearchParams(window.location.search).get("token");
  const instance = new URLSearchParams(window.location.search).get("instance");
  const BASE_URL = `https://certifiedcode.editorx.io/ai-writer/_functions`;

  useEffect(() => {
    setIntercomIdentity();
    setInstance();
  }, []);

  if (token) {
    window.location.href = `https://www.wix.com/installer/install?token=${token}&appId=eee03ca4-7c80-40ff-a2b3-dde84bb6f98b&redirectUrl=https://certifiedcode.editorx.io/ai-writer/_functions/@certifiedcode/base-backend/auth`;
    return (
      <WixDesignSystemProvider features={{ newColorsBranding: true }}>
        <Page height="100vh">
          <Page.Content>
            <Box height={"90vh"} direction="vertical" verticalAlign="middle">
              <Loader text="Loading" />
            </Box>
          </Page.Content>
        </Page>
      </WixDesignSystemProvider>
    );
  }

  if (!instance) {
    return null;
  }

  function setIntercomIdentity() {
    fetch(BASE_URL + "/@certifiedcode/base-backend/intercom", {
      method: "GET",
      headers: {
        Authorization: instance || "",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.hash && data.email) {
          window.Intercom("boot", {
            email: data.email,
            user_hash: data.hash,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function setInstance() {
    fetch(BASE_URL + "/@certifiedcode/base-backend/instance", {
      method: "GET",
      headers: {
        Authorization: instance || "",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        setInstanceData(data);
        setIsUpgraded(data?.instance?.isFree === false);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function getCompletion(type: string) {
    setIsFirstTime(false);
    setIsProcessing(true);
    getCompletionFromApi(type);
  }

  function getCompletionFromApi(type: string) {
    var requestBody = {
      prompt,
      completion,
      instruction: "",
      suffix: "",
    };
    if (type === "edit") {
      requestBody["instruction"] = editInstructions;
    }
    if (type === "insert") {
      // seperate by [insert]
      requestBody["completion"] = completion.split("[insert]")[0];
      requestBody["suffix"] = completion.split("[insert]")[1];
    }
    fetch(BASE_URL + "/completions", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: instance || "",
      },
      body: JSON.stringify(requestBody),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success === true) {
          let code =
            type === "completion"
              ? completion + data.text
              : type === "insert"
              ? completion.replace("[insert]", data.text)
              : data.text;
          setCompletion(code);
          setIsProcessing(false);
          setEditInstructions("");
        } else {
          if (data.canRetry) {
            getCompletionFromApi(type);
          } else {
            if (data.isUpgradeRequired) {
              setIsFairUsageModalOpen(true);
            } else {
              setIsModalOpen(true);
            }
          }
        }
      });
  }

  const isPremiumApp = true;

  return (
    <WixDesignSystemProvider features={{ newColorsBranding: true }}>
      <Notification show>
        <Notification.TextLabel>
          Try the new Document Mode!
        </Notification.TextLabel>
        <Notification.ActionButton
          type="button"
          onClick={() =>
            // redirect to app.docs.certifiedcode.us but keep all the query params
            (window.location.href = `https://app.docs.certifiedcode.us/?${window.location.search.substring(
              1
            )}`)
          }
        >
          Open Docs Editor
        </Notification.ActionButton>
      </Notification>
      <Page height="100vh">
        <Page.Header
          title={
            <Box gap={"SP2"} alignItems="middle">
              <Heading>AI Writer</Heading>
              <Badge size="tiny" skin={isUpgraded ? "premium" : "standard"}>
                {isUpgraded ? "Unlimited" : "Starter"}
              </Badge>
            </Box>
          }
          actionsBar={
            <Box gap={"SP2"} direction="horizontal">
              {isPremiumApp ? (
                <Button
                  skin="premium"
                  disabled={isUpgraded}
                  prefixIcon={<Icons.PremiumFilled />}
                  onClick={() => setIsUpgradeModalOpen(true)}
                >
                  Get Unlimited Completions
                </Button>
              ) : (
                <></>
              )}{" "}
              <Button
                prefixIcon={<Icons.RevertReset />}
                disabled={prompt === "" && completion === ""}
                onClick={() => {
                  setPrompt("");
                  setCompletion("");
                  setEditInstructions("");
                  setIsFirstTime(true);
                }}
              >
                Start over
              </Button>
            </Box>
          }
        />
        <Page.Content>
          <Modal isOpen={isFairUsageModalOpen}>
            <MessageModalLayout
              onCloseButtonClick={() => {
                setIsFairUsageModalOpen(false);
              }}
              primaryButtonOnClick={() => {
                setIsFairUsageModalOpen(false);
              }}
              primaryButtonText="Got it"
              secondaryButtonText="Upgrade Now"
              secondaryButtonOnClick={() => {
                setIsFairUsageModalOpen(false);
                window.open(
                  `https://www.wix.com/apps/upgrade/${"eee03ca4-7c80-40ff-a2b3-dde84bb6f98b"}?appInstanceId=${
                    (instanceData as any)["instance"]["instanceId"]
                  }`
                );
              }}
              title="We couldn't generate the content"
              content={
                <Text>
                  You've reached the maximum usage in the last 24 hours. Try
                  again later
                </Text>
              }
              // footnote={
              //   <Text size="small">
              //     Learn more about our fair use policy <a href=''>here</a>.
              //   </Text>
              // }
            />
          </Modal>
          <Modal
            isOpen={isUpgradeModalOpen}
            shouldCloseOnOverlayClick={true}
            onRequestClose={() => setIsUpgradeModalOpen(false)}
          >
            <AnnouncementModalLayout
              theme="premium"
              title="Start writing content with AI without limit"
              primaryButtonText="Upgrade"
              linkText="Do it later"
              linkOnClick={() => setIsUpgradeModalOpen(false)}
              primaryButtonOnClick={() => {
                window.open(
                  `https://www.wix.com/apps/upgrade/${"eee03ca4-7c80-40ff-a2b3-dde84bb6f98b"}?appInstanceId=${
                    (instanceData as any)["instance"]["instanceId"]
                  }`
                );
              }}
              onCloseButtonClick={() => setIsUpgradeModalOpen(false)}
            >
              <Text>
                Upgrade "AI Writer" to get unlimited completions from the AI
                model trained based on Velo, for you.
              </Text>
            </AnnouncementModalLayout>
          </Modal>
          <Modal isOpen={isModalOpen} screen="desktop">
            <MessageModalLayout
              primaryButtonText="Refresh"
              primaryButtonOnClick={() => window.location.reload()}
              title="Something went wrong"
              content={
                <Text>
                  We are having some issues when generating the content, please
                  refresh this page to continue.
                </Text>
              }
            />
          </Modal>
          <Layout>
            <Cell span={12}>
              <Card>
                <Box minHeight={200}>
                  <Image
                    src={Jasper}
                    fit="contain"
                    borderRadius="15px 0px 0px 15px"
                    height="200px"
                    onClick={() => {
                      window.open(
                        "https://jasper.ai?utm_source=partner&fpr=certifiedcode"
                      );
                    }}
                  />
                  <Box
                    direction="vertical"
                    verticalAlign="space-between"
                    padding="24px 29px 27px"
                    backgroundColor="D80"
                    flexGrow={1}
                  >
                    <Box direction="vertical" gap="SP1">
                      <Text weight="bold">Start Free Trial</Text>
                      <Text size="tiny" weight="thin" secondary>
                        Partner offer from Jasper.ai
                      </Text>
                      <Button
                        as="a"
                        href="https://jasper.ai?utm_source=partner&fpr=certifiedcode"
                        target="_blank"
                      >
                        Get Started
                      </Button>
                    </Box>
                  </Box>
                </Box>
              </Card>
            </Cell>
            {/* <Cell span={12}>
              <Card>
                <MarketingLayout
                  size="tiny"
                  title="Hire our writers who help you build Premium blog."
                  description="Get your blog post written by our expert writers. We will help you to write a premium blog post that will rank on Google."
                  actions={
                    <Box gap="SP1">
                      <Button
                        size="small"
                        id="hire-writer"
                      >
                        Get Started
                      </Button>
                    </Box>
                  }
                  image={
                    <Image
                      width="100px"
                      src={Expert}
                      transparent
                      borderRadius={"999px"}
                    />
                  }
                />
              </Card>
            </Cell> */}
            <Cell span={12}>
              <FormField label="What do you want to write today?">
                {isProcessing ? (
                  <Input
                    status="loading"
                    readOnly={true}
                    value={prompt}
                    size="large"
                    border="round"
                  />
                ) : (
                  <PulseAnimation
                    active={isFirstTime}
                    fluid={true}
                    color="B10"
                    borderRadius="18px"
                  >
                    <Input
                      border="round"
                      value={prompt}
                      maxLength={100}
                      size="large"
                      suffix={[
                        <Box
                          height={"100%"}
                          direction="horizontal"
                          verticalAlign="middle"
                        >
                          <Button
                            size="tiny"
                            onClick={() => getCompletion("completion")}
                            disabled={prompt.length === 0}
                          >
                            {completion.length === 0
                              ? "Generate Content"
                              : "Continue to Complete"}
                          </Button>
                        </Box>,
                      ]}
                      onChange={(e) => setPrompt(e.target.value)}
                      autoFocus={true}
                    />
                  </PulseAnimation>
                )}
              </FormField>
            </Cell>
            <Cell span={isBeta ? 8 : 12}>
              <FormField label="Write">
                <InputArea
                  id="completion"
                  ref={inputAreaRef}
                  minHeight="300px"
                  resizable
                  placeholder="Generated content will appear here"
                  value={completion}
                  onChange={(e: any) => setCompletion(e.target.value)}
                />
              </FormField>
            </Cell>
            {isBeta ? (
              <Cell span={4}>
                <Box direction="vertical" gap="SP4">
                  <Card>
                    <MarketingLayout
                      title="Insert"
                      description={
                        <Text size="small">
                          Place{" "}
                          <Text size="small" weight="bold">
                            [insert]
                          </Text>{" "}
                          to indicate where you want to insert the content.
                        </Text>
                      }
                      actions={
                        <Button
                          size="small"
                          onClick={() => {
                            getCompletion("insert");
                          }}
                          // disable depends on text area have "[insert]" in it
                          disabled={
                            completion.indexOf("[insert]") === -1 ||
                            isProcessing
                          }
                        >
                          Run
                        </Button>
                      }
                      size="tiny"
                    />
                  </Card>
                  <Card>
                    <MarketingLayout
                      title="Instruct (Coming soon)"
                      description={
                        <Text size="small">
                          Tell the{" "}
                          <Text size="small" weight="bold">
                            instruction
                          </Text>{" "}
                          to generate better content
                          <Box gap={"SP2"} direction="vertical">
                            <Box></Box>
                            <Input
                              disabled
                              value={editInstructions}
                              size="small"
                              placeholder="Make it looks pro!"
                              onChange={(e: any) => {
                                setEditInstructions(e.target.value);
                              }}
                            />
                          </Box>
                        </Text>
                      }
                      actions={
                        <Button
                          size="small"
                          disabled
                          // disabled={editInstructions.length === 0 || completion.length === 0 || isProcessing}
                          onClick={() => {
                            getCompletion("edit");
                          }}
                        >
                          Run
                        </Button>
                      }
                      size="tiny"
                    />
                  </Card>
                </Box>
              </Cell>
            ) : null}
          </Layout>
        </Page.Content>
      </Page>
    </WixDesignSystemProvider>
  );
}

export default App;
